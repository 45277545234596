import React from "react";
import styled from '@emotion/styled'
import translate from "../utils/translation"

import pages from '../../static/images/pages.png';
import pen from '../../static/images/pen.svg';
import insightsAllTime from '../../static/images/insights-all-time.png';
import insights from '../../static/images/insights.svg';
import lock from '../../static/images/lock.svg';
import theme from '../../static/images/theme.svg';
import privacyScreen from '../../static/images/privacyScreen.png';
import themeScreen from '../../static/images/themeScreen.png';

const FeaturesWrapper = styled.section`
  @media (max-width: 990px) {
    padding: 0 24px;
  }
`;

const FeaturesContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 40px;
  display: flex;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const FeatureHeading = styled.h4`
  margin: 0 0 19px;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  color: #2f2f2f;
`;

const FeatureContentImage = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  &.col-left-img {
    @media (max-width: 760px) {
      order: 2;
    }
  }
`;

const Img = styled.img`
  display: block;
  max-width: 100%;

  @media (max-width: 760px) {
    margin: 35px auto 0;
  }
`;

const ContentWrapper = styled.div`
  padding-left: 60px;

  &.left {
    padding-left: 0;
    padding-right: 60px;

    @media (max-width: 760px) {
      padding: 0;
    }
  }

  @media (max-width: 760px) {
    padding: 0;
  }
`;

const Content = styled.p`
  color: #2f2f2f;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.88;
`;

const Feature = ({icon, position, title, content}) => (
  <ContentWrapper className={position}>
    <FeatureContentImage src={icon} />
    <FeatureHeading>{title}</FeatureHeading>
    <Content>{content}</Content>
  </ContentWrapper>
);

export default () => (
<FeaturesWrapper>
  <FeaturesContainer>
    <Column className="col-left-img">
      <Img src={pages} alt="Wake up and start writing" />
    </Column>
    <Column>
      <Feature icon={pen} position="right" title={translate("feature1Heading")} content={translate("feature1Content")} />
    </Column>
  </FeaturesContainer>

  <FeaturesContainer>
    <Column>
      <Feature icon={insights} position="left" title={translate("feature2Heading")} content={translate("feature2Content")} />
    </Column>
    <Column>
      <Img src={insightsAllTime} alt="Find out more about yourself" />
    </Column>
  </FeaturesContainer>

  <FeaturesContainer>
    <Column className="col-left-img">
      <Img src={privacyScreen} alt="Your privacy is our priority" />
    </Column>
    <Column>
      <Feature icon={lock} position="right" title={translate("feature3Heading")} content={translate("feature3Content")} />
    </Column>
  </FeaturesContainer>

  <FeaturesContainer>
    <Column>
      <Feature icon={theme} position="left" title={translate("feature4Heading")} content={translate("feature4Content")} />
    </Column>
    <Column>
      <Img src={themeScreen} alt="Write your way" />
    </Column>
  </FeaturesContainer>
</FeaturesWrapper>
);